import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
//import Vue from 'vue'
//import VueRouter from 'vue-router'
//
//Vue.use(VueRouter);
import { isMobile } from 'mobile-device-detect';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('@/views/form.vue'),
  },
  {
    path: '/finish-registration',
    name: 'finish-registration',
    component: () => import('@/views/finishRegistration.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/menu.vue'),
  },
  {
    path: '/qr',
    name: 'qr',
    component: () => import('@/views/qr.vue'),
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3.vue')
      }
    ]
  },
  {
    path: '/no-mobile',
    name: 'no-mobile',
    component: () => import('@/views/no-mobile.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (!isMobile && !!to && to.name != 'no-mobile') {
    next('/no-mobile')
    return;
  }

  next();
});

export default router
